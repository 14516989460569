@import url('./font/stylesheet.css');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Axiforma';
}

li {
  list-style: none;
}

h3 {
  text-transform: uppercase;
  font-family: 'Courier New', Courier, monospace;
}

.links {
  color: aliceblue;
  text-decoration: none;
  font-size: 24px;
}

.links-footer {
  color: aliceblue;
  text-decoration: none;
  font-size:18px;
  padding-left: 10px;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
}
.header-items ul {
  display: flex;
  justify-content: space-around;
  font-weight:normal;
  font-style: normal;
}
.header-items li {
  padding: .5em;
}

.web-logo {
  padding: 1em;
}

.welcome {
  padding: 2em;
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(122,18,30,1) 0%, rgba(38,34,97,1) 100%);
}

.welcome-container {
  display: flex;
  align-items: center;
  padding: 2em;
  justify-content: center;
  flex-direction: column;
}

.welcome-text {
  padding: 0.5em;
  color: aliceblue;
  text-align: center;
  font-size: 50px;
  font-style: normal;
  font-weight: normal;
}

.welcome-text span {
  font-size: 26px;
}

.welcome-btn {
  background-color: white;
  color: #0f0928;
  border-radius: 40px;
  padding: 10px;
  font-size: 20px;
}

.welcome-btn:hover {
  background-color: #0f0928;
  color: aliceblue;
  cursor: pointer;
}

.clients-container {
  display: flex;
  align-items: center;
  justify-content: center;
  color: aliceblue;
  padding-top: 5em;
}

.clients-message {
  padding-left: 4em;
  padding-bottom: 4em;
}

.clients-message p {
  font-size: 20px;
  align-items: center;
  padding: 0em 2em 2em 0em;
}

.clients-heading {
  font-weight: 900;
  font-style: normal;
  font-size: 48px;
}

.slider-container img {
  object-fit: fill;
}

.products-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f0f0f0;
}

.products-title {
  color: #0f0928;
  font-size: 55px;
  font-weight: 900;
  padding-top: 1em;
  text-align: center;
  padding-bottom: .5em;
}

.products-subtitle {
  font-size: 36px;
  font-weight: 300;
  color: #0f0928;
}

.products-list {
  display: flex;
  align-items: center;
}

.products-list img {
  width: 260px;
  height: 260px;
  border-radius: 30px;
}

.products-images {
  padding: 20px;
  border-radius: 10px;
}

.products-content {
  padding: 2em 0em 0.5em 0em;
  color: #0f0928;
}

.products-content h5 {
  font-weight: 600;
  font-size: 20px;
}

.products-btn {
  background-color: #0f0928;
  color: aliceblue;
  border-radius: 40px;
  padding: 10px;
  font-size: 20px;
  margin: 20px;
}

.products-btn:hover {
  background-color: #7a121e;
}

.footer-container {
  display: flex;
  padding: 4em;
  width: 100%;
  color: aliceblue;
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(122,18,30,1) 0%, rgba(38,34,97,1) 100%);
}

.footer-logo {
  padding-right: 5em;
}

.footer-links {
  padding-right: 5em;
}

.foot-link:hover {
  color: #0f0928;
}

.footer-contact {
  padding-right: 5em;
}

.footer-contact p {
  padding-left: 10px;
}

.footer-address p {
  padding-left: 10px;
}

.designer-container {
  background-color: aliceblue;
  height: 1.5em;
}

.designer-container p {
  text-align: center;
  color: #0f0928;
}

.about-container {
  display: flex;
}

.bg-about {
  width: 90%;
  height: 100%;
}

.about-title {
  padding-top: 30px;
  width: 40%;
  height: 30%;
}

.about-content p {
  font-size: 20px;
  font-weight: 500;
  padding-left: 80px;
  padding-right: 80px;
  color: #14025a;
  text-align: justify;
}
.about-paragraph {
  padding-top: 50px;
}

.goals-container {
  display: flex;
}

.contact-container {
  padding: 3em 10em
}

.contact-title {
  font-weight: 900;
  font-size: 72px;
  font-style: normal;
  color: #0f0928;
}

.contact-paragraph {
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
}

.contact-email {
  padding: 5px;
  color: #7a121e;
  font-weight: 500;
}

.contact-email:hover {
  color: #14025a;
}

.contact-label {
  font-size: 20px;
  color: #0f0928;
  font-weight: 500;
}

button.contact-btn {
  background: #00a5ba;
  border: none;
}

button.contact-btn:hover {
  background: #14025a;
  border: none;
}

.direction-container {
  display: flex;
  justify-content: space-around;
}

.access-container {
  display: flex;
  justify-content: center;
  background-color: #bebacd;
  padding-left: 8em;
  padding-right: 7.5em;
}

.access-content {
  padding: 3em;
  color: #0f0928;
}

.Bg_service {
  padding-left: 8em;
  padding-right: 8em;
  background-color: #bebacd;
}

.access-img {
  padding-top: 3em;
  padding-bottom: 3em;
}

.cctv-container {
  background-color: #bebacd;
  display: flex;
  justify-content: center;
  padding-left: 8em;
  padding-right: 8em;
}

.cctv-content {
  color: whitesmoke;
  background-color: #262261;
  padding-top: 4em;
}

.cctv-image {
  padding: 30px;
  background-color: #262261;
}

.alarm-container {
  background-color: #bebacd;
  display: flex;
  justify-content: center;
  padding-left: 8em;
  padding-right: 8em;
}

.alarm-content {
  padding-top: 4em;
  color: #0f0928;
}

.alarm-img {
  padding-top: 4em;
}

.net-container {
  background-color: #DAD7E2;
  display: flex;
  justify-content: center;
  padding-left: 8em;
  padding-right: 8em;
}

.net-content {
  background-color: #262261;
  margin-top: 4em;
  margin-bottom: 3em;
  padding: 10px;
  color: whitesmoke;
}

.net-img {
  padding-top: 4em;
  padding-bottom: 3em;
}

.phone-container {
  background-color: #DAD7E2;
  display: flex;
  justify-content: center;
  padding-left: 8em;
  padding-right: 8em;
}

.phone-content {
  margin-top: 4em;
  margin-bottom: 3em;
  padding: 10px;
  color: #0f0928;
}

.phone-content p{
  font-size: 20px;
}

.phone-img {
  padding-top: 3em;
}

.clients-logo {
  justify-content: space-around;
}

.works-heading {
  padding-top: 1em;
  text-align: center;
  font-weight: bold;
}

.aird {
  width: 300px;
  height: 100px;
}

.aird1 {
  width: 500px;
  height: 200px;
}

.arcadia {
  width: 400px;
  height: 250px;
}

.absa {
  width: 100px;
  height: 100px;
  padding-bottom: 10px;
}

.bayport {
  width: 300px;
  height: 100px;
  padding-bottom: 1em;
}

.bayport1 {
  width: 500px;
  height: 200px;
}

.buildnet {
  width: 300px;
  height: 100px;
  padding-bottom: 2em;
}

.buildnet1 {
  width: 500px;
  height: 200px;
}

.buwembo {
  background-color: #4a2c2a;
  width: 200px;
  height: 100px;
}

.crystal {
  padding-top: 2em;
  width: 400px;
  height: 100px;
}

.cidi {
  width: 500px;
  height: 200px;
}

.efc {
  width: 400px;
  height: 200px;
}

.finance {
  width: 200px;
  height: 200px;
}

.kaffiika {
  width: 200px;
  height: 200px;
}

.kk {
  width: 300px;
  height: 100px;
  padding: 2em;
}

.knight {
  width: 200px;
  height: 100px;
  padding-left: 1em;
}

.knight1 {
  width: 500px;
  height: 200px;
}

.koudijs {
  width: 200px;
  height: 200px;
}

.kta {
  width: 200px;
  height: 200px;
}

.marie {
  width: 300px;
  height: 150px;
}

.uhmg {
  width: 200px;
  height: 200px;
}

.utb {
  width: 300px;
  height: 150px;
}

.sga {
  width: 100px;
  height: 100px;
}

.spedag {
  width: 250px;
  height: 200px;
  padding-left: 2em;
}
.menu-icon {
  display: none;
}

/* Extra small device (phones, 600px and down) */
@media screen and (max-width: 768px){
.web-logo {
  padding: 0em;
}

  .header-items ul {
    display: none;
  }
 
  .menu-icon {
    display: block;
    cursor: pointer;
    color: aliceblue;
  }
  
  .welcome-text {
    padding: 0px;
    font-size: 20px;
  }
  .welcome-text span {
    font-size: 15px;
  }
  .welcome-btn {
    font-size: 10px;
  }
  .clients-container {
    flex-direction: column;
    padding-top: 1em;
  }
  .clients-message {
    padding: 0%;
  }
  .clients-message p {
    font-size: 15px;
  }
  .clients-heading {
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
  }
  .clients-container img {
    width: 200px;
    height: auto;
  }
  .footer-links h5 {
    font-size: 20px;
  }
  .products-title {
    font-size: 24px;
    font-weight: 500;
    padding-top: 1em;
    padding-bottom: 1em;
  }
  .products-subtitle {
    font-size: 20px;
    font-weight: 300;
  }
  .products-images img {
    width: 200px;
    height: 200px;
    padding: 0%;
  }
  .products-btn {
    font-size: small;
  }
  .products-list {
    flex-direction: column;
    align-content: center;
  }
  .footer-container {
    flex-direction: column;
  }
  .footer-logo {
    display: none;
  }
  .bg-about {
    height: 100%;
  }
  .about-title {
    width: 50%;
    height: 10%;
  }
  .about-content p {
    padding-left: 1em;
    padding-right: 0%;
    font-size: medium;
    text-align: start;
  }
  .goals-container {
    flex-direction: column;
  }
  .access-container {
    flex-direction: column;
    padding: 0%;
  }
  .access-img {
    padding: 1em;
    width: auto;
    height: 400px;
  }
  .cctv-container {
    flex-direction: column-reverse;
    padding: 0%;
  }
  .cctv-content {
    padding-left: 2em;
    padding-right: 2em;
  }
  .cctv-image {
    padding: 0%;
    padding-left: 2em;
    width: auto;
    height: 250px;
    padding-bottom: 2em;
  }
  .alarm-container {
    flex-direction: column;
    padding: 0%;
  }
  .alarm-content {
    padding: 2em;
    padding-right: 2em;
  }
  .alarm-img {
    padding: 0%;
    width: auto;
    height: 250px;
    padding-bottom: 2em;
  }
  .phone-container {
    flex-direction: column-reverse;
    padding: 0%;
  }
  .phone-content {
    padding-left: 2em;
    padding-right: 2em;
  }
  .phone-img {
    padding: 0%;
    padding-left: 2em;
    width: auto;
    height: 250px;
  }
  .net-container {
    flex-direction: column;
    padding: 0%;
  }
  .net-content {
    padding: 2em;
  }
  .net-img {
    width: auto;
    height: 250px;
    padding-left: 2em;
    padding-top: 0%;
  }
  .direction-container {
    flex-direction: column;
    justify-content: flex-start;
  }
  .clients-logo {
    padding: 0%;
    object-fit: contain;
    overflow: hidden;
  }
  .logo-container img {
    align-items: center;
  }
  .contact-title {
    font-size: x-large;
  }
  .contact-paragraph {
    font-size: medium;
  }
  .contact-label {
    font-size: medium;
  }
  .Bg_service {
    padding: 0%;
    height: 70px;
    width: 100%;
  }
  .aird {
    padding: 1em;
  }
  .bayport {
    padding: 1em;
  }
  .knight1 {
    width: 200px;
    height: 100px;
  }
  .buildnet1 {
    width: 200px;
    height: 100px;
  }
  .cidi {
    width: 200px;
    height: 100px;
  }
  .absa {
    padding: 1em;
  }
  .buwembo {
    padding: 1em;
    width: 150px;
    height: 100px;
  }
  .arcadia {
    padding: 1em;
  }
  .crystal {
    padding: 1em;
  }
  .contact-container {
    padding: 1em;
  }
  .direction-container {
    align-items: center;
  }
}
/* Small device (portrait tablets and large phones, 600px and up) */
@media screen and (min-width: 600px){
  
}

/* Media devices (landscape tablets, 768px and up) */
@media screen and (min-width: 768px){
   
}
/* Large devices (laptops/desktop, 992px and up) */
@media screen and (min-width: 992px){
   
}
/* Extra large devices (large laptops and desktop, 1200px and up */
@media screen and (min-width: 1200px){
  
}
